import {
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  SignInOutput,
  signOut,
  fetchAuthSession,
} from 'aws-amplify/auth';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Address } from '~/dataModels/Checkout/Checkout';
import { DealerService } from '~/services/DealerService';
import { updateIsDealerAttribute } from '~/utils/AmplifyHelper';

const AuthContext = createContext<{
  user: User;
  setUser: Dispatch<SetStateAction<User>>
  isUserLoggedIn: boolean;
  selectedDealerId: number | null;
  dealerships: Dealer[];
  isAuthLoading: boolean;
  login: (username: string, password: string) => Promise<SignInOutput | null>;
  logout: () => Promise<void>;
  changeDealership: (id: number) => void;
  getCurrentDealer: () => Dealer | undefined;
  errorMessage: string | null;
}>({
  user: {},
  setUser: () => {},
  isUserLoggedIn: false,
  selectedDealerId: null,
  dealerships: [],
  isAuthLoading: true,
  login: async () => null,
  changeDealership: async () => undefined,
  logout: async () => undefined,
  getCurrentDealer: () => undefined,
  errorMessage: null,
});

type User = {
  isDealer?: boolean;
  username?: string;
  email?: string;
  phoneNumber?: string;
  accessToken?: string;
  idToken?: string;
};

export type Dealer = {
  dealerId: number;
  dealerName: string;
  salesPersonId: number;
  salesPersonName: string;
  salesPersonEmail: string;
  distributionId: number;
  address: Address;
};

type DealerResponseModel = {
  Building: string;
  City: string;
  CommissionDistribution: number;
  DealerId: number;
  DealerName: string;
  FullAddress: string;
  Ids: string;
  IsDealerOrder: boolean;
  PostCode: string;
  SalesPersonEmail: string;
  SalesPersonId: number;
  SalesPersonName: string;
  Street: string;
  Suburb: string;
  Unit: string;
};

export const mapDealerResponseModel = (data: DealerResponseModel): Dealer => {
  const dealer: Dealer = {
    dealerId: data.DealerId,
    dealerName: data.DealerName,
    salesPersonEmail: data.SalesPersonEmail,
    salesPersonId: data.SalesPersonId,
    salesPersonName: data.SalesPersonName,
    distributionId: data.CommissionDistribution,
    address: {
      fullAddress: data.FullAddress,
      street: data.Street,
      building: data.Building,
      unit: data.Unit,
      suburb: data.Suburb,
      city: data.City,
      postCode: data.PostCode,
      isManualAddress: true,
    },
  };
  return dealer;
};

export function AuthProvider({
  apiUrl,
  children,
}: {
  apiUrl: string;
  children: React.ReactNode;
}) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [selectedDealerId, setSelectedDealerId] = useState<number>(0);
  const [dealerships, setDealerships] = useState<Dealer[]>([]);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [user, setUser] = useState<User>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const loadUserData = async () => {
      setIsAuthLoading(true);
      await refreshUserData();
      setIsAuthLoading(false);
    };
    loadUserData();
  }, []);

  const getCurrentDealer = () => {
    if (isUserLoggedIn && user.isDealer && dealerships.length > 0) {
      const dsid = localStorage.getItem('dsid');
      let newDealerId: number | undefined = 0;

      if (dsid) {
        newDealerId = parseInt(dsid);
      } else if (dsid === '0' || dsid === null) {
        const defaultDealer = dealerships[0];
        newDealerId = defaultDealer.dealerId;
        localStorage.setItem('dsid', newDealerId.toString());
      }
      setSelectedDealerId(newDealerId);

      return dealerships.find((d) => d.dealerId === newDealerId);
    }

    return undefined;
  };
  const refreshUserData = async () => {
    try {
      const { userId, username, signInDetails } = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const email = userAttributes.email;
      if (!email) {
        throw new Error('Email is required for login');
      }
      const session = await fetchAuthSession();
      setUser({
        username: userAttributes.name,
        email: email,
        phoneNumber: userAttributes.phone_number,
        isDealer: false,
        accessToken: session.tokens?.accessToken?.toString(),
        idToken: session.tokens?.idToken?.toString(),
      });
      setIsUserLoggedIn(true);
      if (
        userAttributes?.['custom:dealer'] === 'allowed' ||
        userAttributes?.['custom:dealer'] === 'TRUE'
      ) {
        setUser({ ...user, isDealer: true });

        if (session.tokens?.accessToken) {
          const dealerInfo = await DealerService.getDealer(
            apiUrl,
            email,
            // session.tokens?.accessToken.toString(),
            //TODO: validate if use id token or access token
            session.tokens?.accessToken?.toString(),
          );

          if (dealerInfo.Data && dealerInfo.Status === 200) {
            const responseData: DealerResponseModel[] = dealerInfo.Data;
            const dealers = responseData.map((d) => mapDealerResponseModel(d));
            setDealerships(dealers);
            const storedActiveDealership = localStorage.getItem('dsid');
            if (storedActiveDealership) {
              const storedDealerId = parseInt(storedActiveDealership);
              const matchedDealer = dealers.find(
                (d) => d.dealerId === parseInt(storedActiveDealership),
              );
              if (matchedDealer) {
                if (matchedDealer.dealerId !== storedDealerId) {
                  setSelectedDealerId(storedDealerId);
                }
              } else if (dealers.length > 0) {
                //use default dealer
                changeDealership(dealers[0].dealerId);
              } else {
                console.log('no dealer found for the current user.');
              }
            }
          } else {
            setErrorMessage(dealerInfo.Message);
            setUser({});
            setIsUserLoggedIn(false);
          }
        }
      } else {
        //it can be marketplace user, or dealer user without custom attribute
        if (session.tokens?.accessToken) {
          const dealerInfo = await DealerService.getDealer(
            apiUrl,
            email,
            // session.tokens?.accessToken.toString(),
            //TODO: validate if use id token or access token
            session.tokens?.accessToken?.toString(),
          );
          if (dealerInfo.Data && dealerInfo.Status === 200) {
            await updateIsDealerAttribute('TRUE');
            setUser({ ...user, isDealer: true });
            const responseData: DealerResponseModel[] = dealerInfo.Data;
            const dealers = responseData.map((d) => mapDealerResponseModel(d));
            setDealerships(dealers);
            const storedActiveDealership = localStorage.getItem('dsid');
            if (storedActiveDealership) {
              const storedDealerId = parseInt(storedActiveDealership);
              const matchedDealer = dealers.find(
                (d) => d.dealerId === parseInt(storedActiveDealership),
              );
              if (matchedDealer) {
                if (matchedDealer.dealerId !== storedDealerId) {
                  setSelectedDealerId(storedDealerId);
                }
              } else if (dealers.length > 0) {
                //use default dealer
                changeDealership(dealers[0].dealerId);
              } else {
                console.log('no dealer found for the current user.');
              }
            }
          } else {
            setErrorMessage(dealerInfo.Message);
            setUser({});
            setIsUserLoggedIn(false);
          }
        }
      }
    } catch (error) {
      console.log('user not logged in');
      setUser({});
      setIsUserLoggedIn(false);
    }
  };

  const changeDealership = (id: number) => {
    setSelectedDealerId(id);
    localStorage.setItem('dsid', id.toString());
  };

  const login = async (username: string, password: string) => {
    try {
      setIsAuthLoading(true);
      const user = await signIn({ username, password });
      if (user.isSignedIn) {
        await refreshUserData();
        setIsAuthLoading(false);
        return user;
      } else {
        return null;
      }
    } catch (error) {
      setIsAuthLoading(false);
      // console.error('Error signing in', error);
      return null;
    }
  };
  const logout = async () => {
    setIsAuthLoading(true);
    await signOut();
    setIsUserLoggedIn(false);
    setDealerships([]);
    setUser({});
    setSelectedDealerId(0);
    localStorage.removeItem('dsid');
    setIsAuthLoading(false);
  };
  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
        isAuthLoading,
        isUserLoggedIn,
        dealerships,
        selectedDealerId,
        changeDealership,
        getCurrentDealer,
        errorMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
